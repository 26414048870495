/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Input, Spin } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { getAllCentresSuggestion } from '@redux/centresSuggestion';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash-es';
import {
  CENTRES_SUGGESTION_TYPES,
  SEARCH_BY_KEYWORD,
  SEARCH_TYPES_CONST,
} from 'configs/localData';
import { useRouter } from 'next/router';
import { useSearchBar } from '@uikit/banners/HomeBannerSearch/SearchBarContext';
import useWindowSize from 'hooks/useWindowSize';
import { SearchIcon } from '@uikit/commons/SVGIcons';
import { getStateNamesOfAustralia } from '@redux/config/selectors';
import useAppDispatch from 'hooks/useAppDispatch';
import { convertSuburbSlugToName, upperFirstChar } from 'utils/tools';
import SuggestionResultSection from './SuggestionResultSection';
import { SearchInputStyles } from './styles';

interface Props {
  isLarge?: boolean;
  suffixPath?: string;
}

const SearchInput = ({ isLarge = true, suffixPath = '' }: Props) => {
  const { t } = useTranslation();

  const { push, query } = useRouter();

  const [loading, setLoading] = useState(false);

  const [visibleDropdown, setVisibleDropdown] = useState(false);

  const [data, setData] = useState([]);

  const { serviceTypeId } = useSearchBar();

  const { width } = useWindowSize();

  const isMobile = width <= 576;

  const inputRef = useRef<Input>();

  const dispatch = useAppDispatch();

  const stateNamesOfAustralia = useSelector(getStateNamesOfAustralia);

  const getCentresSuggest = (params, isRefresh = true) => {
    setLoading(true);
    dispatch(
      getAllCentresSuggestion.initiate({
        limit: 10,
        offset: 0,
        serviceTypeId:
          serviceTypeId && serviceTypeId !== 'all' ? serviceTypeId : undefined,
        ...params,
      }),
    )
      .then((response) => {
        setLoading(false);
        if (response?.data?.results)
          setData([...(isRefresh ? [] : data), ...response?.data?.results]);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleSearch = () => {
    const textSearch = inputRef?.current?.state?.value?.trim();
    sessionStorage.setItem('searchTerm', textSearch);

    if (textSearch) {
      getCentresSuggest({ text: textSearch }, true);
    } else {
      setData([]);
    }
  };

  const redirectSearchByKeyword = () => {
    const textSearch = inputRef?.current?.state?.value?.trim();

    const checkHasSearchType = textSearch?.indexOf(
      `${t(SEARCH_TYPES_CONST[query.searchType as string]?.name)}`,
    );
    const textSearchRemovedSearchType =
      checkHasSearchType !== -1
        ? textSearch?.replace(
            ` - ${t(SEARCH_TYPES_CONST[query.searchType as string]?.name)}`,
            '',
          )
        : textSearch;

    if (textSearchRemovedSearchType) {
      push({
        pathname: `/centres${suffixPath}`,
        query: {
          q: textSearchRemovedSearchType,
          searchType: SEARCH_BY_KEYWORD,
        },
      });
    }
  };

  const getSearchTextFromUrl = () => {
    const checkHasSearchType = inputRef.current.state.value
      ?.trim()
      .indexOf(`${t(SEARCH_TYPES_CONST[query.searchType as string]?.name)}`);
    if (query?.suburb) {
      return `${convertSuburbSlugToName(query.suburb)}, ${query.state}, ${
        query.postcode
      } - ${t(SEARCH_TYPES_CONST.city.name)}`;
    }
    if (query?.postcode)
      return `${query.postcode} - ${t(SEARCH_TYPES_CONST.postcode.name)}`;
    if (query?.state)
      return `${
        stateNamesOfAustralia?.[query.state as string] || query.state
      } - ${t(SEARCH_TYPES_CONST.state.name)}`;
    if (query?.searchType === SEARCH_TYPES_CONST.brand.value) {
      return `${query?.q} - ${upperFirstChar(SEARCH_TYPES_CONST.brand.value)}`;
    }

    return `${query?.q || ''}${
      SEARCH_TYPES_CONST[query?.searchType as string]?.name
        ? [
            checkHasSearchType === -1
              ? ` - ${t(SEARCH_TYPES_CONST[query.searchType as string].name)}`
              : '',
          ]
        : ''
    }`;
  };

  useEffect(() => {
    if (inputRef.current.state) {
      (inputRef.current.state.value as string) = getSearchTextFromUrl();
      sessionStorage.setItem('suggestedTerm', inputRef.current.state.value);
    }
  }, [query, stateNamesOfAustralia]);

  useEffect(() => {
    const textSearch = inputRef?.current?.state?.value?.trim();
    textSearch && getCentresSuggest({ text: textSearch }, true);
  }, [serviceTypeId]);

  return (
    <SearchInputStyles
      className="position-relative"
      isMobile={isMobile}
      isLarge={isLarge}
    >
      <Input
        ref={inputRef}
        placeholder={t('input.searchBar')}
        suffix={
          !isMobile && (
            <Button
              className="btn-search-bar"
              size={isLarge ? 'large' : 'middle'}
              shape="circle"
              type="primary"
              onClick={redirectSearchByKeyword}
              icon={<SearchOutlined />}
            />
          )
        }
        prefix={isMobile && <SearchIcon className="text-gray mr-5" />}
        onChange={debounce(handleSearch, 600)}
        onFocus={() => setVisibleDropdown(true)}
        onBlur={() => setVisibleDropdown(false)}
        onPressEnter={redirectSearchByKeyword}
      />
      <div
        className={`search-input__result ${
          visibleDropdown ? '' : 'search-input__result-hidden'
        }`}
      >
        {loading && (
          <div className="flex-center search-input__loading">
            <Spin />
          </div>
        )}
        {CENTRES_SUGGESTION_TYPES.map((item) => (
          <SuggestionResultSection
            key={item.value}
            item={item}
            data={data?.filter((itemData) => itemData.type === item.value)}
            suffixPath={suffixPath}
          />
        ))}
      </div>
    </SearchInputStyles>
  );
};

export default SearchInput;
