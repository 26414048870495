import styled from 'styled-components';

export const HomeBannerSearchStyles = styled.div`
  padding: 32px 0 80px 0;

  .home-banner-search {
    position: relative;
    border-radius: 0px 40px 40px 40px !important;
    background: #f8effc;
    padding: 132px 48px 300px 48px;
    z-index: 2;

    .home-banner {
      &__title-wrapper {
        z-index: 5;
        position: relative;
      }

      &__title {
        font-weight: 800;
        font-size: 72px;
        line-height: 90px;
        background: linear-gradient(89.55deg, #ff8ad1 0.16%, #26c1fc 103.73%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      &__desc {
        font-size: 24px;
        line-height: 48px;
        max-width: 686px;
        color: var(--text-secondary);
      }

      &__kids-img {
        position: absolute;
        z-index: 2;
        width: 30vw;
        max-width: 422px;
        top: -70px;
        right: 70px;
      }

      &__plane-pink {
        position: absolute;
        z-index: 2;
        width: 70px;
        left: -20px;
        bottom: -20px;
      }

      &__plane-group {
        position: absolute;
        z-index: 2;
        width: 70px;
        right: 20px;
        top: 70px;
      }

      &__plane-blue {
        position: absolute;
        z-index: 2;
        width: 70px;
        right: -22px;
        top: -36px;
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    padding-bottom: 140px;
    .home-banner-search {
      padding-top: 40px;

      .home-banner {
        &__title-wrapper {
          text-align: center;
        }

        &__kids-img {
          display: none;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    padding-bottom: 120px;
    .home-banner-search {
      padding: 30px 30px 100px 30px;

      .home-banner {
        &__title-wrapper {
          text-align: center;
        }

        &__title {
          font-size: 50px;
          line-height: 60px;
        }
      }
    }
  }

  @media only screen and (max-width: 576px) {
    padding-bottom: 120px;
    .home-banner-search {
      padding: 35px 14px 80px 14px;

      .home-banner {
        &__title-wrapper {
          text-align: center;
          margin-bottom: 23px;
        }

        &__title {
          font-size: 28px;
          line-height: 36px;
        }

        &__plane-blue,
        &__plane-pink {
          display: none;
        }
      }
    }
  }

  @media only screen and (max-width: 320px) {
    .home-banner-search {
      padding: 35px 14px 80px 14px;

      .home-banner {
        &__title {
          font-size: 22px;
          line-height: 30px;
        }
      }
    }
  }
`;

export const SearchBarStyles = styled.div`
  padding: 32px;
  background: linear-gradient(
    87.68deg,
    #ffffff 0.21%,
    rgba(255, 255, 255, 0.72) 89.27%,
    rgba(255, 255, 255, 0.8) 101.39%
  );
  border: 1px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 12px 80px rgba(21, 40, 64, 0.12);
  backdrop-filter: blur(100px);
  border-radius: 20px;
  z-index: 3;
  position: absolute;
  max-width: calc(100% - 96px);
  width: calc(100% - 96px);

  .ant-tabs {
    padding: 0 20px !important;

    .ant-tabs-nav {
      margin-bottom: 0 !important;
    }

    .ant-tabs-tab {
      border: 1px solid #fff !important;
    }

    .ant-tabs-tab:not(.ant-tabs-tab-active) {
      background: rgba(255, 255, 255, 0.6) !important;
    }

    .ant-tabs-tab-active {
      background: var(--primary) !important;

      .ant-tabs-tab-btn {
        color: #fff !important;
      }
    }
  }

  .search-bar {
    &__default-location {
      margin-top: 12px;
      display: flex;

      .anticon {
        line-height: 28px;
      }

      &-name {
        font-size: 16px;
        color: var(--color-grey-l2);
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    max-width: calc(100% - 60px);
    width: calc(100% - 60px);
    padding: 16px;
  }

  @media only screen and (max-width: 576px) {
    border-radius: 12px;
    max-width: calc(100% - 28px);
    width: calc(100% - 28px);
    padding: 8px;
    .ant-tabs {
      padding: 0 8px !important;
    }

    .ant-tabs-tab {
      font-size: 14px;
      padding: 6px 8px !important;
      line-height: 24px;
      font-weight: 600;
    }

    .search-bar {
      &__default-location {
        &-name {
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }
`;
