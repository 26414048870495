import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CENTRES_SUGGESTION_TYPES_CONST } from 'configs/localData';
import { useRouter } from 'next/router';
import { compactSuburbDisplay } from 'utils/dataUtils';
import {
  getLinkCentreDetail,
  getLinkCentresPostcode,
  getLinkCentresSuburb,
  getLinkSuburbProfile,
} from 'utils/tools';
import { useSearchBar } from '@uikit/banners/HomeBannerSearch/SearchBarContext';
import { useDispatch } from 'react-redux';
import { trackingEvent } from '@redux/eventTracking/actions';
import { EVENT_TRACKING } from 'configs/eventTracking';
import {
  CentreSuggestionTypes,
  SuggestionResultItem,
} from 'types/searchSuggestions';
import { SuggestionResultSectionStyles } from './styles';

interface Props {
  item?: CentreSuggestionTypes;
  data?: SuggestionResultItem[];
  suffixPath?: string;
}

const SuggestionResultSection = ({ item, data, suffixPath }: Props) => {
  const { t } = useTranslation();
  const { push } = useRouter();
  const dispatch = useDispatch();

  const { serviceTypeId } = useSearchBar();

  const getName = (suggestItem) => {
    switch (item.value) {
      case CENTRES_SUGGESTION_TYPES_CONST.city.value:
      case CENTRES_SUGGESTION_TYPES_CONST.suburbProfile.value: {
        return compactSuburbDisplay(suggestItem);
      }
      case CENTRES_SUGGESTION_TYPES_CONST.postcode.value:
        return suggestItem.postCode;
      default:
        return suggestItem.name;
    }
  };

  const getFilter = () => {
    if (serviceTypeId && serviceTypeId !== 'all') {
      return {
        filter: JSON.stringify({
          serviceTypeId: {
            $in: [serviceTypeId],
          },
        }),
      };
    }
    return {};
  };

  const onClickItem = (suggestItem, name) => {
    const filter = getFilter();

    switch (suggestItem?.type) {
      case CENTRES_SUGGESTION_TYPES_CONST.city.value: {
        const suburbLink = getLinkCentresSuburb(suggestItem, suffixPath);
        push({
          pathname: suburbLink.pathname,
          query: {
            ...suburbLink.query,
            ...filter,
          },
        });
        break;
      }
      case CENTRES_SUGGESTION_TYPES_CONST.suburbProfile.value: {
        const suburbLink = getLinkSuburbProfile(suggestItem, suffixPath);
        push({
          pathname: suburbLink.pathname,
          query: {
            ...suburbLink.query,
            ...filter,
          },
        });
        break;
      }
      case CENTRES_SUGGESTION_TYPES_CONST.centre.value: {
        dispatch(
          trackingEvent({
            eventName: EVENT_TRACKING.searchByCentreName,
            eventData: {
              centreId: suggestItem?.id,
              centreName: suggestItem?.name,
            },
          }),
        );
        push(getLinkCentreDetail(suggestItem));
        break;
      }

      case CENTRES_SUGGESTION_TYPES_CONST.brand.value:
        push({
          pathname: `/centres${suffixPath}`,
          query: {
            q: name,
            searchType: suggestItem?.type,
            brandId: suggestItem?.id,
            ...filter,
          },
        });
        break;

      case CENTRES_SUGGESTION_TYPES_CONST.postcode.value: {
        const postcodeLink = getLinkCentresPostcode(suggestItem, suffixPath);
        push({
          pathname: postcodeLink.pathname,
          query: {
            ...postcodeLink.query,
            ...filter,
          },
        });
        break;
      }

      default:
        push({
          pathname: `/centres${suffixPath}`,
          query: {
            q: name,
            searchType: suggestItem?.type,
            ...filter,
          },
        });
    }
  };

  return data?.length ? (
    <SuggestionResultSectionStyles className="suggestion-result">
      <div className="suggestion-result__title mb-10">
        {item.IconCPN && <item.IconCPN className="mr-10 text-highlight" />}
        {t(item.text)}
      </div>

      <div className="suggestion-result__content">
        {data.map((suggestItem, idx) => {
          const name = getName(suggestItem);
          return (
            <div
              key={String(idx)}
              className="suggestion-result__content-item pointer"
              onMouseDown={() => onClickItem(suggestItem, name)}
              role="presentation"
            >
              {`${name} - ${t(item.name)}`}
            </div>
          );
        })}
      </div>
    </SuggestionResultSectionStyles>
  ) : null;
};

SuggestionResultSection.propTypes = {
  item: PropTypes.object,
  data: PropTypes.array,
  suffixPath: PropTypes.string,
};

export default SuggestionResultSection;
