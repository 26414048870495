import { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const initialValue = {
  serviceTypeId: null,
  setServiceTypeId: (value) => value,
};

export const SearchBarContext = createContext(initialValue);

export const SearchBarProvider = ({ children }) => {
  const [serviceTypeId, setServiceTypeId] = useState();

  const value = {
    serviceTypeId,
    setServiceTypeId,
  };

  return (
    <SearchBarContext.Provider value={value}>
      {children}
    </SearchBarContext.Provider>
  );
};

SearchBarProvider.propTypes = {
  children: PropTypes.node,
};

export const useSearchBar = () => {
  const { serviceTypeId, setServiceTypeId } = useContext(SearchBarContext);

  return {
    serviceTypeId,
    setServiceTypeId,
  };
};
