import { Tabs } from 'antd';
import { useSelector } from 'react-redux';
import { getCurrentLocation, getServiceTypes } from '@redux/config/selectors';
import { useTranslation } from 'react-i18next';
import { LocationMarkerIcon } from '@uikit/commons/SVGIcons';
import SearchInput from 'components/rest/SearchInput';
import { SearchBarStyles } from './styles';
import { useSearchBar } from './SearchBarContext';

const { TabPane } = Tabs;

const SearchBar = () => {
  const { t } = useTranslation();
  const serviceTypes = useSelector(getServiceTypes);
  const { formattedAddress } = useSelector(getCurrentLocation) || {};
  const { setServiceTypeId } = useSearchBar();

  return (
    <SearchBarStyles>
      <Tabs type="card" defaultActiveKey="all" onChange={setServiceTypeId}>
        <TabPane key="all" tab={t('serviceTypes.allServiceTypes')} />
        {serviceTypes?.map((item) => (
          <TabPane key={item.id} tab={item.name} />
        ))}
      </Tabs>

      <SearchInput />

      <div className="search-bar__default-location">
        <LocationMarkerIcon className="text-highlight mr-10 xs:mr-4 size-xl" />
        <span className="search-bar__default-location-name ellipsis-t">
          {formattedAddress}
        </span>
      </div>
    </SearchBarStyles>
  );
};

export default SearchBar;
