import styled from 'styled-components';
import { respondTo } from 'utils/variables';

export const TabBannerStyles = styled.div`
  gap: 4px;
  ${respondTo.md`
    gap: 0;
  `};
  ${respondTo.sm`
    gap: 0;
  `};
  ${respondTo.xs`
   padding-left: 0;
   padding-right: 0;
   justify-content: space-between;
   margin-bottom: 5px;
   gap: 0;
`}
`;

export const TabItemStyles = styled.a`
  padding: 16px 50px;
  background: var(--bg-grey-l1);
  border-radius: 12px 12px 0 0;
  font-size: 16px;
  color: var(--text-gray-white);
  font-weight: 600;
  word-wrap: break-word;

  ${respondTo.md`
      font-size: 14px;
      padding: 10px 40px;
      margin: 0 2px;
  `};

  ${respondTo.sm`
      color: var(--text-grey-l1);
      font-size: 14px;
      padding: 10px 40px;
      margin: 0 2px;
  `};

  ${respondTo.xs`
      padding: 8px 19px;
      border-radius: 8px;
      font-size: 12px;
      text-align: center;
      flex: 1 1 33%;
      margin: 0 2px;
  `};

  @media only screen and (max-width: 375px) {
    padding: 8px 17px;
  }

  &.active {
    color: var(--text-white);
    background: ${({ activeBackgroundColor }) => activeBackgroundColor};
  }
`;
