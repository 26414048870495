import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { HOME_BANNER_TABS } from 'configs/localData';
import { TabBannerStyles, TabItemStyles } from './styles';

const TabBanner = () => {
  const { t } = useTranslation();
  const { pathname, push } = useRouter();

  const handleRedirect = (item) => {
    item?.url !== pathname && push(item?.url);
  };
  return (
    <TabBannerStyles className="home-tab d-flex flex-row">
      {HOME_BANNER_TABS.map((item) => (
        <TabItemStyles
          className={`align-center ${pathname === item.url && 'active'}`}
          activeBackgroundColor={item.activeBackgroundColor}
          href="#"
          onClick={(e) => {
            e.preventDefault();
            handleRedirect(item);
          }}
        >
          {t(item.text)}
        </TabItemStyles>
      ))}
    </TabBannerStyles>
  );
};

export default TabBanner;
