import { useTranslation } from 'react-i18next';
import TabBanner from '@uikit/banners/TabBanner';
import SearchBar from './SearchBar';
import { HomeBannerSearchStyles } from './styles';
import { SearchBarProvider } from './SearchBarContext';

const HomeBannerSearch = () => {
  const { t } = useTranslation();
  return (
    <HomeBannerSearchStyles>
      <TabBanner />

      <div className="home-banner-search">
        <div className="home-banner__title-wrapper mb-32">
          <span className="home-banner__title mb-20">
            {t('home.banner.title')}
          </span>
          <span className="home-banner__dot text-pastel-pink size-20">
            &#9679;
          </span>
        </div>
        <SearchBarProvider>
          <SearchBar />
        </SearchBarProvider>
        <img
          className="home-banner__plane-pink"
          src="/images/paper-plane-pink.png"
          alt="kindicare"
        />
        <img
          className="home-banner__plane-blue"
          src="/images/paper-plane-blue.png"
          alt="kindicare"
        />
        <img
          className="home-banner__kids-img"
          src="/images/kids-group.png"
          alt="kindicare"
        />
      </div>
    </HomeBannerSearchStyles>
  );
};

export default HomeBannerSearch;
