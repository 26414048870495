import styled from 'styled-components';

export const SuggestionResultSectionStyles = styled.div`
  font-size: 16px;
  .suggestion-result {
    &__title {
      font-weight: bold;
      line-height: 24px;
      color: var(--text-secondary);
      text-transform: uppercase;
      .anticon {
        font-size: 22px;
      }
    }
    &__content > div {
      padding: 6px 0;
    }
  }
`;

export const SearchInputStyles = styled.div`
  .ant-input-affix-wrapper {
    background: linear-gradient(
      87.67deg,
      #ffffff 59.13%,
      rgba(255, 255, 255, 0.92) 101.63%
    );
    border: 2px solid rgba(211, 204, 204, 0.5);
    box-sizing: border-box;
    border-radius: ${({ isMobile }) => (isMobile ? '8px' : '24px')};
    padding: ${({ isMobile, isLarge }) =>
      isMobile ? '12px 8px' : isLarge && '20px 30px'};

    .ant-input {
      font-size: 18px;
    }
  }

  @media only screen and (max-width: 576px) {
    .ant-input-affix-wrapper {
      font-size: 14px;
      border: 1px solid rgba(211, 204, 204, 0.5);
      .ant-input {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        &::placeholder {
          font-size: 14px;
        }
      }
    }
    .search-input__result > .suggestion-result {
      padding: 20px !important;
    }
  }

  .search-input__result {
    background: #ffffff;
    box-shadow: 0px 8px 36px rgba(39, 40, 51, 0.12);
    border-radius: 24px;
    position: absolute;
    width: 100%;
    z-index: 10;
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
    transition: all 0.4s ease;

    > .suggestion-result {
      padding: 10px 40px;
    }
    & > .suggestion-result:first-child {
      padding-top: 30px;
    }
    & > .suggestion-result:last-child {
      padding-bottom: 30px;
    }
  }

  .search-input__loading {
    padding: 30px 40px;
  }

  .search-input__result-hidden {
    max-height: 0;
  }
`;
